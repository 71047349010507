import Backbone from 'backbone';
import _ from 'lodash';
import moment from 'moment';
import IsoDuration from 'iso8601-duration';

import { formatWithCurrency } from '../util/currency.js';
import getConfigValue from '../util/configuration.js';
import i18nUtils from '../util/i18n.js';

import ControllerFactory from '../modules/controller.factory.js';
import UserManager from '../modules/usermanager.js';

import PayMainView from '../view/pay/main.vue';
import Vue from "vue";
import { SUBSCRIPTION_STATUS } from "../modules/subscription.js";

import URI from '../util/uri.js';

const PayMainViewComponent = Vue.extend(PayMainView);

const category = 'unlimited';

export class PayController {
    constructor () {
        this._payView = null;
    }

    async go (appView, router, page=null, productName=null) {
        await this.renderPayView(appView, page, productName);
    }

    async renderPayView(appView, page, productName) {
        const user = UserManager.instance.getUser();
        const subscription = user.getSubscription();
        const subscriptionStatus = subscription.getStatus();

        const userMethods = await PayController.getUserMethods();
        const isPChomeUser = userMethods && userMethods.includes('pchome');
        const audience = 'web';
        const brandingId = 'lingvist';

        if (isPChomeUser) {
            return PayController.redirectToPChome();
        }

        const [ productCatalogDataResponse, userServicesResponse, braintreeClientTokenResponse ] = await Promise.all([
            PayController.getProductCatalogData(audience),
            PayController.getUserServices(),
            PayController.getBraintreeClientToken(),
        ]);

        const { products } = productCatalogDataResponse;
        const { token } = braintreeClientTokenResponse;
        const userServices = userServicesResponse;
        const enableThreeDSecure = getConfigValue('pay-braintree-enable-3dsecure') && getConfigValue('pay-braintree-force-3dsecure');
        let _activeProductName = null;
        let paymentProvider = null;
        let _userHasLifetime = false;
        let userHasRecurringService = false;
        let userCanManageSubscription = false;
        let activeProduct = null;
        let userOnPreTrial = (subscriptionStatus && subscriptionStatus.status === SUBSCRIPTION_STATUS.PRE_TRIAL);
        let introTrialAvailable = false;

        userServices.map(service => {
            if (service.subscription.is_recurring) {
                if (service.subscription.status === 'active') {
                    userHasRecurringService = true;
                    _activeProductName = service.product_name;
                    paymentProvider = (service.payment_provider) ? service.payment_provider : null;
                    userCanManageSubscription = (paymentProvider === 'braintree');
                    activeProduct = this.prepareActiveProduct(service);
                }
            } else if (service.is_infinite) {
                _userHasLifetime = true;
                userHasRecurringService = true;
                _activeProductName = service.product_name;
            }
        });

        if (_userHasLifetime) {
            Backbone.history.navigate('account?tab=subscription', { trigger: true });
        } else {
            const preparedProducts = this.prepareProducts(products, brandingId, _activeProductName);

            preparedProducts.map(product => {
                if (product.free_trial_duration) {
                    introTrialAvailable = true;
                }
            });

            let product;
            let pageName = page;

            if (page === 'checkout' && productName) {
                product = _.find(preparedProducts, {name: productName});
            } else if (page === 'success') {
                Backbone.history.navigate(`subscriptions/success`, { trigger: false });
            } else if (page === 'voucher') {
                Backbone.history.navigate(`subscriptions/voucher`, { trigger: false });
            } else if (page === 'failure') {
                Backbone.history.navigate(`subscriptions/failure`, { trigger: false });
            } else if (page === 'manage' && !subscription.isSubscriptionActive() || !activeProduct) {
                pageName = 'catalog';
                Backbone.history.navigate(`subscriptions`, { trigger: false });
            }

            this._payView = new PayMainViewComponent({
                data: {},
                propsData: {
                    pageName,
                    user,
                    subscription,
                    userHasRecurringService,
                    userCanManageSubscription,
                    paymentProvider,
                    userOnPreTrial,
                    introTrialAvailable,
                    enableThreeDSecure,
                    products: preparedProducts,
                    product,
                    activeProduct,
                    brandingId,
                    token
                }
            });

            this._payView.$on('checkout', product => {
                let productName = (product && product.hasOwnProperty('name')) ? product.name : '';
                Backbone.history.navigate(`subscriptions/checkout/${productName}`, { trigger: false });
            });

            this._payView.$on('navigate', (to) => {
                switch (to) {
                    case 'catalog':
                        Backbone.history.navigate(`subscriptions`, { trigger: false });
                        break;
                    case 'success':
                        Backbone.history.navigate(`subscriptions/success`, { trigger: false });
                        break;
                    case 'manage':
                        Backbone.history.navigate(`subscriptions/manage`, { trigger: false });
                        break;
                    case 'voucher':
                        Backbone.history.navigate(`subscriptions/voucher`, { trigger: false });
                        break;
                    case 'lingvist:account?profile':
                        Backbone.history.navigate(`account`, { trigger: true });
                        break;
                }
            });

            appView.setMainView(this._payView);
        }
    }

    static async getProductCatalogData (audience) {
        const user = UserManager.instance.getUser();
        const response = await user.getPayApi().getProductCatalog(category, audience);

        return Promise.resolve(response);
    }

    static async getUserServices () {
        const user = UserManager.instance.getUser();
        const response = await user.getPayApi().getUserServices();
        let services = [];
        if ('services' in response) {
            services = response.services;
        }

        return Promise.resolve(services);
    }

    static async getUserMethods () {
        const user = UserManager.instance.getUser();
        const locale = i18nUtils.currentInterfaceLanguage;

        const response = await user.getPayApi().getUserMethods(locale);

        return Promise.resolve(response);
    }

    static async getPChomeAuthCode () {
        const user = UserManager.instance.getUser();
        const response = await user.getPayApi().getPChomeAuthCode();

        return Promise.resolve(response);
    }

    static async getBraintreeClientToken () {
        const user = UserManager.instance.getUser();
        const response = await user.getPayApi().getBraintreeClientToken();

        return Promise.resolve(response);
    }

    static async redirectToPChome () {
        const locale = i18nUtils.currentLocale;
        const pcHomeAuthCode = await PayController.getPChomeAuthCode();

        if (pcHomeAuthCode && pcHomeAuthCode.code) {
            window.location.href = `${getConfigValue('pchome-purchase-url')}?code=${encodeURIComponent(pcHomeAuthCode.code)}&locale=${encodeURIComponent(locale)}`;
        }
    }

    openProductOnPayWeb (productName) {
        new URI(`lingvist:pay?page=buy&catalog=unlimited&product=${productName}`).navigateTo();
    }

    prepareActiveProduct(product) {
        if (product) {
            const duration = IsoDuration.parse(product.subscription.period);
            const months = duration.years * 12 + duration.months;
            const amount = Number(parseFloat(product.subscription.price.amount).toFixed(2));
            const currentLocale = i18nUtils.currentLocale;

            product.intervalMonths = months;
            product.amount = amount;
            product.formattedPrice = `${product.subscription.price.currency} ${amount}`;
            product.formattedBillingInterval =  i18nUtils.prop('months', { months: months }, null, 'time' , null);
            product.formattedBillingDate = moment(product.subscription.next_billing_ts).locale(currentLocale).format('ll');
        }

        return product;
    }

    prepareProducts (products, brandingId, activeProductName) {

        return products.map(product => {
            const { name, plan: { period }, free_trial_duration } = product;
            const price = product.prices[0];

            const {
                amount: amountSrc,
                currency,
                meta: {
                    discount_percent: discount,
                    original_price: amountOrig,
                    monthly_price: amountMon,
                },
            } = price;
            const isDiscounted = !_.isNil(discount) && !_.isEqual(0, discount);
            const amountTotal = parseFloat(amountSrc);
            const amount = formatWithCurrency(amountTotal, currency);
            const amountUsual = isDiscounted ? formatWithCurrency(amountOrig, currency) : amount;
            const amountPeriod = formatWithCurrency(amountMon, currency);
            const isRecurring = product.plan.is_recurring;
            const isUserExistingRecurringService = _.isEqual(name, activeProductName);
            const isPromoted = product.meta && product.meta.is_promoted;
            let isSinglePurchase = false;

            let amountPeriodUsual = amountUsual;
            let heading = i18nUtils.prop(name, null, null, "subscription_name");
            let textUsualPrice = "";
            let textPeriodPrice = "";
            let textPerPeriod = "";
            let trialLengthText = free_trial_duration ? i18nUtils.prop("trial_offer_product_button_trial_length", { product_trial_days: moment.duration(free_trial_duration).asDays(), hours: 0, months: 0 }).replace(/<[^>]+>/g, "") : '';

            if (name === 'unlimited-lifetime') {
                textPerPeriod = i18nUtils.prop("lifetime", null, null, "product_payment_frequency");

                if (isDiscounted) {
                    amountPeriodUsual = formatWithCurrency(parseFloat(amountOrig), currency);
                    textUsualPrice = amountPeriodUsual;
                }
            } else {
                if (_.isEqual("P1M", period) || _.isEqual("P30D", period)) {
                    if (_.isEqual("P30D", period)) {
                        textPerPeriod = i18nUtils.prop("pay_product_recurring_price_per_30d");
                    }

                    textPerPeriod = i18nUtils.prop("per_month", null, null, "product_payment_frequency");

                    if (isDiscounted) {
                        amountPeriodUsual = formatWithCurrency(parseFloat(amountOrig), currency);
                    }
                } else if (_.isEqual("P1Y", period)) {
                    textPerPeriod = i18nUtils.prop("per_year", null, null, "product_payment_frequency");

                    if (isDiscounted) {
                        amountPeriodUsual = formatWithCurrency(parseFloat(amountOrig), currency);
                    }
                } else if (_.isEqual("P10Y", period) || _.isEqual("P20Y", period)) {
                    isSinglePurchase = true;
                    textPerPeriod = i18nUtils.prop("single_purchase", null, null, "product_payment_frequency");
                }
                textUsualPrice = i18nUtils.prop("pay_product_recurring_price_amount", {
                    amount: amountPeriodUsual,
                    asterisk: _.isEqual("P1M", period) ? "" : "*",
                });

                textPeriodPrice = i18nUtils.prop("pay_product_recurring_price_amount", {
                    amount: amountPeriod,
                    asterisk: _.isEqual("P1M", period) ? "" : "*",
                });
            }

            return {
                ...product,
                isPromoted,
                heading,
                amountTotal,
                amountPeriod,
                amountPeriodUsual,
                isRecurring,
                isDiscounted,
                trialLengthText,
                textUsualPrice,
                textPeriodPrice,
                textTotalPrice: amount,
                textPerPeriod,
                isUserExistingRecurringService,
                isSinglePurchase
            };
        });
    }
}

export const pay_controller_factory = new ControllerFactory(PayController);

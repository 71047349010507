var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "voucher-page" },
    [
      _c(
        "div",
        { staticClass: "heading" },
        [
          _c("voucher-illustration"),
          _vm._v(" "),
          this.voucherInfo && this.voucherInfo.code
            ? _c("div", { staticClass: "heading-text" }, [
                _c("h1", [_vm._v(_vm._s(this.voucherInfo.code))]),
                _vm._v(" "),
                _vm.headingSubTitle
                  ? _c("p", [_vm._v(_vm._s(_vm.headingSubTitle))])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.voucherInfoLoading
        ? _c("loader")
        : _vm.productsWithVoucherInfo && !_vm.isVoucherCheckout
        ? [
            _c(
              "product-catalog",
              _vm._g(
                {
                  attrs: {
                    showTitle: false,
                    products: this.productsWithVoucherInfo,
                    userHasRecurringService: this.userHasRecurringService,
                    userCanManageSubscription: this.userCanManageSubscription,
                    brandingId: this.brandingId,
                    voucherInfo: this.voucherInfo,
                    paymentProvider: this.paymentProvider,
                    showMessages: false,
                  },
                },
                _vm.$listeners
              )
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "messages" },
              [
                _vm.alreadySubscribedMessage
                  ? [
                      _vm.alreadySubscribedMessage
                        ? _c("p", { staticClass: "error" }, [
                            _vm._v(_vm._s(_vm.alreadySubscribedMessage)),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.subscribedOnPlatformMessage
                        ? _c("p", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.subscribedOnPlatformMessage
                              ),
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("p", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("pay_product_recurring_text_billed")
                    ),
                  },
                }),
                _vm._v(" "),
                this.user && this.user.profile && this.user.profile.email
                  ? _c("p", {
                      domProps: {
                        innerHTML: _vm._s(
                          this.$i18n("voucher_page_current_account_note", {
                            args: { user_email: this.user.profile.email },
                          })
                        ),
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c("v-button", {
              staticClass: "resetVoucher",
              attrs: { i18n: { id: "voucher_page_back_btn" }, filled: false },
              nativeOn: {
                click: function ($event) {
                  return _vm.resetVoucher.apply(null, arguments)
                },
              },
            }),
          ]
        : _vm.isVoucherCheckout
        ? [
            _c("div", { staticClass: "voucher-card" }, [
              _c("header", [
                _c("h3", {
                  domProps: {
                    innerHTML: _vm._s(
                      this.$i18n("100percent_discount", {
                        collection: "voucher_page_voucher_card_title",
                      })
                    ),
                  },
                }),
                _vm._v(" "),
                _c("p", [_vm._v(_vm._s(this.voucherCardHeaderPeriod))]),
              ]),
              _vm._v(" "),
              _c(
                "footer",
                [
                  _c("v-button", {
                    staticClass: "redeemVoucher",
                    attrs: {
                      i18n: { id: "voucher_page_redeem_btn" },
                      filled: true,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.redeemVoucher.apply(null, arguments)
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("v-button", {
              staticClass: "resetVoucher",
              attrs: { i18n: { id: "voucher_page_back_btn" }, filled: false },
              nativeOn: {
                click: function ($event) {
                  return _vm.resetVoucher.apply(null, arguments)
                },
              },
            }),
          ]
        : _c(
            "div",
            { staticClass: "voucher-input" },
            [
              _c("div", { staticClass: "input-row" }, [
                _c(
                  "div",
                  { staticClass: "icon" },
                  [_vm.voucherInfo ? _c("TickIcon") : _c("VoucherIconSmall")],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.voucherCode,
                      expression: "voucherCode",
                    },
                  ],
                  attrs: {
                    id: "voucher-code",
                    name: "voucher-code",
                    placeholder: _vm.inputPlaceholder,
                    type: "text",
                    maxlength: "50",
                  },
                  domProps: { value: _vm.voucherCode },
                  on: {
                    focus: function ($event) {
                      _vm.inFocus = true
                    },
                    blur: function ($event) {
                      _vm.inFocus = false
                    },
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.applyVoucher.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.voucherCode = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("v-button", {
                attrs: {
                  i18n: { id: "voucher_page_voucher_apply_btn" },
                  filled: true,
                  disabled: this.voucherCode.length === 0,
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.applyVoucher.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _vm.errorMessages && _vm.errorMessages.length > 0
                ? _c(
                    "div",
                    { staticClass: "error" },
                    _vm._l(_vm.errorMessages, function (error) {
                      return _c("p", [_vm._v(_vm._s(error))])
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
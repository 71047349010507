
import AsyncDestroyable from './async.destroyable.js';
import { PayAPI } from './pay.api.js';

import i18nUtils from '../util/i18n.js';


export class PayAPICache extends AsyncDestroyable {
    /**
     * @typedef {object} SubscriptionData
     * @property {string} status
     * @property {string|null} expiration_ts
     */

    constructor (user) {
        super(['getUserServices', 'getUserServices', 'getProductCatalog']);

        this._user = user;

        // Instance fields
        this._pay_api = new PayAPI(user);
        this._cache = {};
    }

    destroy () {
        delete this._user;

        let pay_api = this._pay_api;
        delete this._pay_api;

        this.removeAllListeners();

        return Promise.resolve()
            .then(() => pay_api.destroy());
    }

    /**
     * Clears all of the cached values
     *
     * @return {Promise}
     */
    invalidateCache () {
        return Promise.resolve()
            .then(() => {
                this._cache = {};
            });
    }

    /**
     * Caches the the request under the given key
     *
     * @param {string} key - The key to cache the request under
     * @param {function} request - The function for generating the request
     * @return {Promise}
     * @private
     */
    _cache_request (key, request) {
        if (this._cache[key] === undefined) {
            this._cache[key] = request();
        }

        return this._cache[key];
    }

    initialize () {
        // Initialize cache for select requests asynchronously
        let locale = i18nUtils.currentInterfaceLanguage;
        return Promise.all([
            this._cache_request(`services-${locale}`, () => this._pay_api.getUserServices({locale: locale})),
            this._cache_request(`methods-${locale}`, () => this._pay_api.getUserMethods({ locale: locale })),
        ]).then(() => {
            this.emit('services-updated');
            this.emit('methods-updated');
        });
    }

    /**
     *
     * @return {Promise<[]<UserService>>}
     */
    getUserServices (locale=null) {
        locale = locale || i18nUtils.currentInterfaceLanguage;

        return Promise.resolve()
            .then(() => this._cache_request(`services-${locale}`, () => this._pay_api.getUserServices({locale: locale})))
            .then(response => response);
    }

    /**
     *
     * @return {Promise<[]<UserSubscription>>}
     */
    getUserSubscriptions () {
        return Promise.resolve()
            .then(() => this._cache_request(`subscriptions`, () => this._pay_api.getUserSubscriptions()))
            .then(response => response.subscriptions);
    }

    /**
     *
     * @param subscription_uuid
     * @return {Promise<{error: string|undefined, status: string|undefined}>}
     */
    cancelUserSubscription (subscription_uuid) {
        return Promise.resolve()
            .then(() => this._pay_api.cancelUserSubscription(subscription_uuid));
    }

    getProductCatalog (category = 'unlimited', audience = 'web') {
        return Promise.resolve()
            .then(() => this._cache_request(`products-${category}-audience-${audience}`, () => this._pay_api.getProductCatalog({ category, audience })));
    }

    getUserMethods (locale=null) {
        return Promise.resolve()
            .then(() => this._cache_request(`methods-${locale}`, () => this._pay_api.getUserMethods({ locale: locale })));
    }

    getPChomeAuthCode () {
        return Promise.resolve()
            .then(() => this._cache_request(`pchome-auth-code`, () => this._pay_api.getPChomeAuthCode()));
    }

    getBraintreeClientToken () {
        return Promise.resolve()
            .then(() => this._cache_request(`braintree-client-token`, () => this._pay_api.getBraintreeClientToken()));
    }

    braintreeCheckout (params) {
        return Promise.resolve()
            .then(() => this._pay_api.braintreeCheckout(params));
    }

    braintreePaymentMethodDefault (params) {
        return Promise.resolve()
            .then(() => this._pay_api.braintreePaymentMethodDefault(params));
    }

    voucherCheck (params) {
        return Promise.resolve()
            .then(() => this._pay_api.voucherCheck(params));
    }

    voucherCheckout (params) {
        return Promise.resolve()
            .then(() => this._pay_api.voucherCheckout(params));
    }
}

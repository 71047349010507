<template>
    <div class="catalog" id="catalog">
        <campaign1020-heading v-if="has1020product" />
        <free-trial-heading v-else-if="this.userOnPreTrial || this.introTrialAvailable" />
        <h1 v-html="this.$i18n('pay_product_recurring_title')" v-else-if="showTitle" />
        <div class="cards">
            <div v-for="product in productsModified" class="card" :class="{ 'promoted': product.isPromoted, 'semi-promoted': product.isSemiPromoted, 'subscribed': product.isUserExistingRecurringService, 'voucherApplied': product.voucherInfoFormatted }">
                <header>
                    <h3>{{ product.heading }}</h3>
                </header>
                <div class="info">
                    <div class="price">
                        <template v-if="product.voucherInfoFormatted && !product.voucherInfoFormatted.is_forced_free_trial">
                            <div class="usualPriceWithSaving">
                                <span class="usualPrice">{{ product.amountPeriodUsual }}</span>
                                <div class="saving">-{{ product.voucherInfoFormatted.discount }}</div>
                            </div>
                            <div class="wrapper">
                                <span class="totalPrice">{{ product.voucherInfoFormatted.totalWithCurrency }}</span>
                                <span class="textPeriod">/ {{ product.textPerPeriod }}</span>
                                <div class="promo-trial" v-if="product.voucherInfoFormatted && product.voucherInfoFormatted.duration && product.voucherInfoFormatted.trialLengthText"><span>{{ product.voucherInfoFormatted.trialLengthText }}</span></div>
                                <div class="promo-trial" v-else-if="product.free_trial_duration && product.trialLengthText"><span>{{ product.trialLengthText }}</span></div>
                                <div class="periodPrice" v-if="product.voucherInfoFormatted.monthlyWithCurrency && product.voucherInfoFormatted.monthlyWithCurrency !== product.voucherInfoFormatted.totalWithCurrency">
                                    <div><span>{{ product.voucherInfoFormatted.textPeriodPrice }}</span> <span v-html="$i18n('per_month', { collection: 'product_payment_frequency' })"/></div>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <span class="usualPrice" v-if="product.isDiscounted">{{ product.textUsualPrice }}</span>
                            <div class="wrapper">
                                <span class="totalPrice">{{ product.textTotalPrice }}</span>
                                <span class="textPeriod">/ {{ product.textPerPeriod }}</span>
                                <div class="promo-trial" v-if="product.voucherInfoFormatted && product.voucherInfoFormatted.duration && product.voucherInfoFormatted.trialLengthText"><span>{{ product.voucherInfoFormatted.trialLengthText }}</span></div>
                                <div class="promo-trial" v-else-if="product.free_trial_duration && product.trialLengthText"><span>{{ product.trialLengthText }}</span></div>
                                <div class="periodPrice" v-if="product.textPeriodPrice.length > 0 && product.textPeriodPrice !== product.textTotalPrice">
                                    <div><span>{{ product.textPeriodPrice }}</span> <span v-html="$i18n('per_month', { collection: 'product_payment_frequency' })"/></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <footer>
                    <v-button @click.native="selectProduct(product)"
                              :color="(product.isPromoted || product.isSemiPromoted) ? 'primary' : 'secondary'"
                              :filled="!!(product.isPromoted || product.isSemiPromoted)"
                              :disabled="userHasRecurringService"
                              :icon="product.isUserExistingRecurringService && !product.voucherInfo ? 'tick' : null"
                              :text="selectProductButtonText(product)" />
                </footer>
            </div>
        </div>
        <div class="messages" v-if="showMessages && alreadySubscribedMessage && paymentProvider === 'braintree'">
            <p class="error">{{ alreadySubscribedMessage }}</p>
            <p v-if="subscribedOnPlatformMessage" v-html="subscribedOnPlatformMessage" />
        </div>
    </div>
</template>

<script>
import VButton from "ViewComponent/v-button.vue";
import i18nUtils from "Util/i18n.js";
import freeTrialHeading from "./free-trial-heading.vue";
import Campaign1020Heading from "./campaign-10-20-heading.vue";

export default {
    name: 'catalog',
    components: {
        Campaign1020Heading,
        VButton,
        freeTrialHeading
    },
    data() {
        return {
            has1020product: false
        }
    },
    props: {
        products: {
            type: Array
        },
        brandingId: {
            type: String
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        userHasRecurringService: {
            type: Boolean,
            default: false
        },
        userCanManageSubscription: {
            type: Boolean,
            default: false
        },
        userOnPreTrial: {
            type: Boolean,
            default: false
        },
        introTrialAvailable: {
            type: Boolean,
            default: false
        },
        showMessages: {
            type: Boolean,
            default: true
        },
        voucherInfo: {
            type: Object
        },
        paymentProvider: {
            type: String
        }
    },
    methods: {
        selectProduct(product) {
            this.$emit('product-selected', product);
        },
        selectProductButtonText(product) {
            if (product && !product.isRecurring) {
                return i18nUtils.prop('pay_product_recurring_purchase');
            } else {
                if (product.isUserExistingRecurringService && !product.voucherInfo) {
                    return i18nUtils.prop('pay_product_recurring_info_subscribed');
                } else {
                    return i18nUtils.prop('subscription_button_select');
                }
            }
        },
        mouseClick(event) {
            if (event && event.target && event.target.hasAttribute('data-uri')) {
                const dataUri = event.target.dataset.uri;
                if (dataUri) {
                    this.$emit('navigate', dataUri);
                }
            }
        }
    },
    computed: {
        productsModified () {
            const voucherForProductUuid = (this.voucherInfo && this.voucherInfo.discount && this.voucherInfo.discount.product_uuid) ? this.voucherInfo.discount.product_uuid : null;
            let _productsModified = [];
            let p1m = this.products.find(product => product.plan.period === 'P1M');

            this.products.forEach(product => {
                if (product.hasOwnProperty('visible') && product.visible === false && (this.selectProduct.uuid !== product.uuid) && !product.isUserExistingRecurringService) {
                    return
                }
                if (product.plan && product.plan.period === 'P1Y' && p1m && p1m.hasOwnProperty('prices')) {
                    product.saving = Math.floor((p1m.prices[0].meta.monthly_price - product.prices[0].meta.monthly_price) / p1m.prices[0].meta.monthly_price * 100);
                }

                // promote specific products

                if (product.name === 'unlimited-20-years') {
                    product.isPromoted = true
                    this.has1020product = true
                }

                if (product.name === 'unlimited-10-years') {
                    product.isSemiPromoted = true
                    this.has1020product = true
                }

                if (voucherForProductUuid) {
                    if (product.uuid === voucherForProductUuid) {
                        _productsModified.push(product);
                    }
                } else {
                    _productsModified.push(product);
                }
            });

            return _productsModified.reverse();
        },
        alreadySubscribedMessage() {
            if (this.userHasRecurringService && this.paymentProvider === 'braintree') {
                return i18nUtils.prop('subscription_page_already_subscribed_note');
            }
        },
        subscribedOnPlatformMessage() {
            if (this.userHasRecurringService && this.paymentProvider === 'braintree') {
                return i18nUtils.prop('subscription_page_cancel_subscription_web_description');
            }
        },
    },
    mounted () {
        document.addEventListener('click', this.mouseClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.mouseClick);
    }
}
</script>

<style lang="scss">
    @import '~Styles/colors';
    @import '~Styles/mixins';

    $pay-radius: pxToRem(12);

    div.catalog {
        //max-width: pxToRem(736);
        margin: 2rem auto;
        @include respond-to('small') {
            margin: 1rem auto;
        }
        > div.cards {
            display: flex;
            flex-direction: row;
            padding: 1rem;
            gap: 3rem;
            justify-content: center;
            flex-wrap: wrap;
            > div.card {
                display: flex;
                flex-flow: column;
                flex-direction: column;
                overflow: hidden;
                user-select: none;
                background-color: #fff;
                border-radius: pxToRem(12);
                min-width: 18rem;

                > header,
                > footer,
                > div.info {
                    padding: pxToRem(24);
                }
                > header {
                    position: relative;
                    background-color: $colorBackgroundDarker;
                    color: $colorSourcePrimary;
                    border-top-left-radius: $pay-radius;
                    border-top-right-radius: $pay-radius;
                    > h3 {
                        font-size: pxToRem(18);
                        line-height: pxToRem(21);
                        font-weight: $font-bold;
                    }
                }
                > div.info {
                    color: $colorSourceSecondary;
                    flex: 2;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    > div.price {
                        display: flex;
                        flex-direction: column;

                        > div.wrapper {
                            > div.promo-trial {
                                margin: .25rem 0;
                                > span {
                                    display: inline-block;
                                    background-color: $colorBackgroundDarker;
                                    border-radius: pxToRem(12);
                                    padding: .5rem .75rem;
                                    color: $colorSourcePrimary;
                                }
                            }
                        }

                        div.saving {
                            color: $colorSourcePrimary;
                            border: 1px solid $colorSourcePrimary;
                            padding: .3rem .4rem;
                            border-radius: .5rem;
                            margin-left: .5rem;
                            font-size: fontSize(12);
                        }
                        > div.usualPriceWithSaving {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: .5rem;
                            > div.saving {
                                border-color: $colorSourceSecondary;
                                color: $colorSourceSecondary;
                                padding: .2rem .4rem;
                            }
                        }
                        span.usualPrice {
                            text-decoration: line-through;
                            margin-bottom: 0;
                        }
                        span.totalPrice {
                            font-size: 1.8em;
                            color: $colorSourcePrimary;
                        }
                        span.textPeriod {
                            margin-left: 0.25em;
                        }
                        div.periodPrice {
                            color: $colorPurplePrimary;
                            font-size: 1.2em;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            > div.saving {
                                margin-left: .5rem;
                            }
                        }
                    }
                }
                > footer {
                    margin-top: auto;
                    padding-top: 0;
                    display: flex;
                    > button {
                        flex: 1;
                    }
                }
                &.promoted,
                &.semi-promoted {
                    box-shadow: 0 0 24px rgba(0, 0, 0, 0.25);
                    margin-left: 1rem;
                    > header {
                        background-color: $colorPurplePrimary;
                        color: #fff;
                    }
                    background-color: #fff;
                    transform: scale(1.1);
                    @include respond-to('medium') {
                        transform: none;
                        margin-left: unset;
                    }
                    span.totalPrice {
                        font-size: 2em;
                    }
                }
                &.semi-promoted {
                    > header {
                        background-color: rgba($colorPurplePrimary, 0.7);
                        color: #fff;
                    }
                }
                &.subscribed {
                    pointer-events: none;
                }
            }
            @include respond-to('medium') {
                flex-direction: column;
                > div.card {
                    flex: 1;
                }
            }
        }
        > div.messages {
            margin: 1rem auto;
            display: flex;
            flex-direction: column;
            gap: .5rem;
            max-width: 40rem;
            > p {
                &.error {
                    color: $colorIncorrect;
                }
                > em {
                    font-weight: $font-bold;
                }
                a {
                    text-decoration: underline;
                    color: $colorHyperlink;
                }
            }
        }
    }
</style>

<template>
    <div class="voucher-page">
        <div class="heading">
            <voucher-illustration />
            <div class="heading-text" v-if="this.voucherInfo && this.voucherInfo.code">
                <h1>{{ this.voucherInfo.code }}</h1>
                <p v-if="headingSubTitle">{{ headingSubTitle }}</p>
            </div>
        </div>
        <loader v-if="voucherInfoLoading" />

        <template v-else-if="productsWithVoucherInfo && !isVoucherCheckout">
            <product-catalog
                v-on="$listeners"
                :showTitle="false"
                :products="this.productsWithVoucherInfo"
                :userHasRecurringService="this.userHasRecurringService"
                :userCanManageSubscription="this.userCanManageSubscription"
                :brandingId="this.brandingId"
                :voucherInfo="this.voucherInfo"
                :paymentProvider="this.paymentProvider"
                :showMessages="false"
            />
            <div class="messages">
                <template v-if="alreadySubscribedMessage">
                    <p v-if="alreadySubscribedMessage" class="error">{{ alreadySubscribedMessage }}</p>
                    <p v-if="subscribedOnPlatformMessage" v-html="subscribedOnPlatformMessage" />
                </template>
                <p v-html="this.$i18n('pay_product_recurring_text_billed')" />
                <p v-if="this.user && this.user.profile && this.user.profile.email" v-html="this.$i18n('voucher_page_current_account_note' , { args: { user_email: this.user.profile.email }})" />
            </div>
            <v-button :i18n="{ id: 'voucher_page_back_btn' }" @click.native="resetVoucher" :filled="false" class="resetVoucher" />
        </template>

        <template v-else-if="isVoucherCheckout">
            <div class="voucher-card">
                <header>
                    <h3 v-html="this.$i18n('100percent_discount', { collection: 'voucher_page_voucher_card_title' })" />
                    <p>{{ this.voucherCardHeaderPeriod }}</p>
                </header>
                <footer>
                    <v-button :i18n="{ id: 'voucher_page_redeem_btn' }" @click.native="redeemVoucher" :filled="true" class="redeemVoucher" />
                </footer>
            </div>
            <v-button :i18n="{ id: 'voucher_page_back_btn' }" @click.native="resetVoucher" :filled="false" class="resetVoucher" />
        </template>

        <div class="voucher-input" v-else>
            <div class="input-row">
                <div class="icon">
                    <TickIcon v-if="voucherInfo" />
                    <VoucherIconSmall v-else />
                </div>
                <input id="voucher-code" name="voucher-code" :placeholder="inputPlaceholder" type="text" maxlength="50" @focus="inFocus = true" @blur="inFocus = false" v-on:keyup.enter="applyVoucher" v-model="voucherCode" />
            </div>
            <v-button :i18n="{ id: 'voucher_page_voucher_apply_btn' }" @click.native="applyVoucher" :filled="true" :disabled="this.voucherCode.length === 0" />
            <div class="error" v-if="errorMessages && errorMessages.length > 0">
                <p v-for="error in errorMessages">{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import i18nUtils from "Util/i18n.js";
    import VButton from "ViewComponent/v-button.vue";
    import loader from "ViewComponent/loader.vue";
    import ProductCatalog from "./catalog.vue";
    import TickIcon from 'Images/account/tick.svg';
    import VoucherIllustration from 'Images/pay/illustrations/voucher-page-illustration.svg';
    import VoucherIconSmall from 'Images/pay/ic_voucher_24x24.svg';
    import _ from "lodash";
    import { formatWithCurrency } from "Util/currency.js";
    import { EventBus } from "Util/vue-event-bus.js";
    import Backbone from "backbone";
    import UserManager from "Modules/usermanager.js";
    import moment from "moment";

    export default {
        name: 'voucher',
        components: {
            loader,
            ProductCatalog,
            VButton,
            VoucherIllustration,
            TickIcon,
            VoucherIconSmall
        },
        props: {
            user: {
                type: Object,
                required: true
            },
            products: {
                type: Array
            },
            brandingId: {
                type: String
            },
            userHasRecurringService: {
                type: Boolean
            },
            userCanManageSubscription: {
                type: Boolean
            },
            userOnPreTrial: {
                type: Boolean
            },
            introTrialAvailable: {
                type: Boolean
            },
            paymentProvider: {
                type: String
            },
            voucherCodeInitial: {
                type: String
            }
        },
        data() {
            return {
                voucherCode: (this.voucherCodeInitial) ? this.voucherCodeInitial : '',
                voucherInfo: null,
                errorMessages: [],
                inFocus: false,
                voucherInfoLoading: false,
                productsWithVoucherInfo: null
            }
        },
        computed: {
            isVoucherCheckout() {
                let isVoucherCheckout = false;
                if (this.voucherInfo) {
                    const { is_usable, discount: { percentage, is_forced_free_trial } } = this.voucherInfo;
                    // if it's 100% off voucher, checkout is done to another endpoint
                    isVoucherCheckout = is_usable && percentage === "100.00" && !is_forced_free_trial;
                }

                return isVoucherCheckout;
            },
            alreadySubscribedMessage() {
                if (this.userHasRecurringService) {
                    return i18nUtils.prop('voucher_page_already_subscribed_note');
                }
            },
            subscribedOnPlatformMessage() {
                if (this.userHasRecurringService) {
                    switch(this.paymentProvider) {
                        case 'google-in-app':
                            return i18nUtils.prop('voucher_page_cancel_subscription_android_description');
                        case 'apple-in-app':
                            return i18nUtils.prop('voucher_page_cancel_subscription_ios_description');
                        case 'braintree':
                            return i18nUtils.prop('voucher_page_cancel_subscription_web_description');
                    }
                }
            },
            inputPlaceholder() {
                return i18nUtils.prop('voucher_page_input_field_title');
            },
            headingSubTitle() {
                if (this.voucherInfo.discount && this.voucherInfo.discount.percentage && !this.voucherInfo.discount.is_forced_free_trial && this.voucherInfo.discount.percentage !== "100.00") {
                    return i18nUtils.prop('voucher_page_banner_discount_description');
                } else if (this.voucherInfo && this.voucherInfo.discount.percentage === "100.00") {
                    return i18nUtils.prop('voucher_page_banner_free_time_description');
                } else if (this.voucherInfo.duration && this.product.free_trial_duration && moment.duration(this.voucherInfo.duration).asDays() > moment.duration(this.product.free_trial_duration).asDays()) {
                    return i18nUtils.prop('voucher_page_banner_extended_free_trial_description');
                }
            },
            voucherCardHeaderPeriod() {
                if (this.isVoucherCheckout && this.voucherInfo) {
                    if (this.voucherInfo && this.voucherInfo.discount && this.voucherInfo.discount.duration) {
                        return moment.duration(this.voucherInfo.discount.duration).humanize();
                    } else if (this.productsWithVoucherInfo && this.productsWithVoucherInfo.length > 0) {
                        let _product = _.find(this.productsWithVoucherInfo, { uuid: this.voucherInfo.discount.product_uuid });
                        if (_product && _product.hasOwnProperty('plan') && _product.plan.period) {
                            return moment.duration(_product.plan.period).humanize();
                        }
                    }
                }
            }
        },
        methods: {
            resetVoucher() {
                this.voucherCode = '';
                this.voucherInfo = null;
                this.errorMessages = [];
                this.voucherInfoLoading = false;
                this.productsWithVoucherInfo =  null;
            },
            resetError() {
                this.errorMessages = [];
            },
            async redeemVoucher() {
                if (this.isVoucherCheckout) {
                    const { code, is_usable, discount: { percentage, product_uuid } } = this.voucherInfo;

                    if (is_usable && percentage === "100.00") {
                        EventBus.$emit('pay:loading', true);
                        this.resetError();
                        let _product = _.find(this.productsWithVoucherInfo, { uuid: product_uuid });

                        const params = {
                            product: (_product && _product.name) ? _product.name : 'unlimited-1-month',
                            voucher_code: code
                        };

                        return Promise.resolve()
                            .then(() => this.user.getPayApi().voucherCheckout(params))
                            .then(() => this.user.getPayApi().invalidateCache())
                            .then(() => UserManager.instance.getUser().sync())
                            .then(() => {
                                EventBus.$emit('pay:checkout-successful', { receipt: false });
                                EventBus.$emit('pay:loading', false);
                                Backbone.trigger('userSubChanged');
                                this.resetError();
                            }).catch(result => {
                                if (result && result.error) {
                                    this.errorMessages.push(i18nUtils.prop(this.errorNameByError(result.error), null, null, "pay_errors"));
                                    EventBus.$emit('pay:loading', false);
                                }
                            });
                    }
                }
            },
            formatVoucherInfo(voucherInfo, product) {
                if (voucherInfo && product) {
                    const { plan: { period } } = product;
                    const { voucher, price_info } = voucherInfo;
                    const { is_usable, discount: { percentage, duration, is_forced_free_trial } } = voucher;

                    if (is_usable) {
                        let total_amount, monthly_amount, currency;
                        if (price_info && price_info.hasOwnProperty('total_amount') && price_info.hasOwnProperty('currency')) {
                            total_amount = price_info.total_amount;
                            currency = price_info.currency;
                        }
                        if (price_info && price_info.hasOwnProperty('monthly_amount')) {
                            monthly_amount = price_info.monthly_amount;
                        }
                        return {
                            total: (total_amount) ? total_amount : null,
                            monthlyWithCurrency: (monthly_amount && currency) ? formatWithCurrency(monthly_amount, currency) : null,
                            textPeriodPrice: i18nUtils.prop("pay_product_recurring_price_amount", {
                                amount: monthly_amount,
                                asterisk: _.isEqual("P1M", period) ? "" : "*",
                            }),
                            totalWithCurrency: (total_amount && currency) ? formatWithCurrency(total_amount, currency) : null,
                            discount: `${parseFloat(percentage).toFixed(0)}%`,
                            duration,
                            trialLengthText: duration ? i18nUtils.prop("trial_offer_product_button_trial_length", { product_trial_days: moment.duration(duration).asDays(), hours: 0, months: 0 }).replace(/<[^>]+>/g, "") : null,
                            is_forced_free_trial
                        }
                    }
                }
            },
            async applyVoucher () {
                if (this.voucherCode.length > 0) {
                    this.voucherInfoLoading = true;
                    let _productsWithVoucherInfo = [];
                    let _errors = [];

                    if (this.products && this.products.length > 0) {
                        for (const product of this.products) {
                            try {
                                const price_uuid = _.get(product, ["prices", 0, "uuid"], null);
                                const params = {
                                    voucher_code: this.voucherCode,
                                    product_uuid: product.uuid,
                                    price_uuid: price_uuid,
                                };

                                let result = await this.user.getPayApi().voucherCheck(params);
                                const { is_usable } = result.voucher;

                                if (is_usable) {
                                    this.voucherInfo = result.voucher;
                                    let formattedVoucherInfo = this.formatVoucherInfo(result, product);
                                    _productsWithVoucherInfo.push({
                                        ...product,
                                        voucherInfoFormatted: formattedVoucherInfo,
                                        voucherInfo: result
                                    })

                                    this.resetError();
                                } else {
                                    const voucher_error = is_usable ? null : "voucher_inactive";
                                    if (voucher_error) {
                                        _errors.push(i18nUtils.prop(voucher_error, null, null, "pay_errors"));
                                        this.voucherInfo = null;
                                    }
                                }
                            } catch (err) {
                                if (err && err.error) {
                                    _errors.push( i18nUtils.prop(this.errorNameByError(err.error), null, null, "pay_errors"));
                                }
                            }
                        }
                        if (_errors.length > 0) {
                            this.errorMessages = _.uniq(_errors);
                        }
                    }

                    this.productsWithVoucherInfo = (_productsWithVoucherInfo.length > 0 ) ? _productsWithVoucherInfo : null;
                    this.voucherInfoLoading = false;
                }
            },

            errorNameByError: function (error) {
                if (!error) {
                    return "integration_failure";
                }

                if (_.isEqual("voucher_not_found", error)) {
                    return "voucher_invalid";
                }

                if (_.isEqual("invalid_product", error)) {
                    return "voucher_invalid";
                }

                return "integration_failure";
            },

            mouseClick(event) {
                if (event && event.target && event.target.hasAttribute('data-uri')) {
                    const dataUri = event.target.dataset.uri;
                    if (dataUri) {
                        this.$emit('navigate', dataUri);
                    }
                }
            },

            fetchVoucherFromFragment() {
                let bodyFragment = document.body.dataset.fragment;
                const codeMatch = bodyFragment.match(/code=([^&]+)/);
                const code = codeMatch ? codeMatch[1] : null;
                if (code) {
                    this.voucherCode = code;
                    this.applyVoucher();
                }
            },
        },
        mounted () {
            document.addEventListener('click', this.mouseClick);

            if (this.voucherCodeInitial && this.voucherCodeInitial.length > 0) {
                this.applyVoucher();
            } else {
                this.fetchVoucherFromFragment();
            }
        },
        beforeDestroy() {
            document.removeEventListener('click', this.mouseClick);
        }
    }
</script>

<style lang="scss">
    @import '~Styles/colors';
    @import '~Styles/mixins';
    $pay-radius: pxToRem(12);

    div.voucher-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 80vh;
        @include respond-to('small') {
            min-height: unset;
        }
        > div.heading {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2rem;
            > svg {
                &:hover {
                    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
                }
            }
            > div.heading-text {
                > h1 {
                    text-align: start;
                    font-weight: $font-bold;
                    margin-bottom: .5rem;
                }
                @include respond-to('small') {
                    text-align: center;
                    > h1 {
                        text-align: center;
                        margin: 0;
                    }
                }
            }
            @include respond-to('small') {
                flex-direction: column;
                gap: 1rem;
                margin: 0 2rem;
            }
        }
        > div.voucher-input {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            min-width: pxToRem(420);
            max-width: pxToRem(420);
            margin: 0 auto;
            @include respond-to('small') {
                min-width: 100vw;
                max-width: unset;
                margin: unset;
                padding: 0 1rem;
            }
            > div.input-row {
                display: flex;
                flex-direction: row;
                border-bottom: 2px solid $colorBackgroundDarker;
                padding: pxToRem(4);
                &.focus {
                    border-bottom-color: $colorSourcePrimary;
                }
                div.icon {
                    flex: 0;
                    width: pxToRem(20);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                input {
                    flex: 1;
                    margin: 0 1rem;
                    font-family: Consolas, Monaco, Courier, Lucida Console, monospace;
                    font-weight: $font-bold;
                }
            }
            > div.error {
                color: $colorIncorrect;
                text-align: center;
            }
        }

        > div.voucher-card {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            user-select: none;
            background-color: #fff;
            border-radius: pxToRem(12);
            min-width: 18rem;
            margin: 2rem;

            > header,
            > footer {
                padding: pxToRem(24);
            }

            > header {
                position: relative;
                background-color: $colorPurplePrimary;
                color: #fff;
                border-top-left-radius: $pay-radius;
                border-top-right-radius: $pay-radius;
                > h3 {
                    font-size: pxToRem(18);
                    line-height: pxToRem(21);
                    font-weight: $font-bold;
                }
            }
            > footer {
                margin-top: auto;
                display: flex;
                > button {
                    flex: 1;
                }
            }
        }

        > button.resetVoucher {
            margin: 1rem 0 2rem;
            min-width: 18rem;
        }

        > div.messages {
            margin: 1rem;
            display: flex;
            flex-direction: column;
            gap: .5rem;
            max-width: 40rem;
            > p {
                &.error {
                    color: $colorIncorrect;
                }
                > em {
                    font-weight: $font-bold;
                }
                a {
                    text-decoration: underline;
                    color: $colorHyperlink;
                }
            }
        }

        @keyframes shake {
            10%, 90% {
                transform: translate3d(-1px, 0, 0);
            }

            20%, 80% {
                transform: translate3d(2px, 0, 0);
            }

            30%, 50%, 70% {
                transform: translate3d(-4px, 0, 0);
            }

            40%, 60% {
                transform: translate3d(4px, 0, 0);
            }
        }
    }
</style>
